import * as React from "react";
import styled from "styled-components";
import Layout from "../components/utilis/Layout";
import { Main } from "../components/utilis/common";
import Section from "../components/utilis/Section";
import { theme } from "../components/utilis/colors";
import { Link } from "gatsby";
import LF_logo from "../assets/logo/lf_logo_white.svg";

const Page404 = styled.div`
  width: 100%;
  text-align: center;
  font-size: 3.2rem;
  color: ${theme.text};
`;
const ButtonWrapper = styled.div`
  width: 100%;
  padding: 4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;
const Logo = styled.img`
  width: 100%;
  max-width: 8rem;
  display: flex;
`;

const ButtonAction = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${theme.text};
  font-size: 1.4rem;
  display: flex;
  padding: 0.4rem 1.2rem;
  transition: 300ms;
  border-radius: 0.2rem;
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Main>
        <Section bgColor={theme.mainBackground} textColor={theme.text}>
          <Page404>
            <LogoWrapper>
              <Logo src={LF_logo} />
            </LogoWrapper>
            ups .... <br />
            there is nothing here <br />
          </Page404>
          <ButtonWrapper>
            <ButtonAction to="/">Go to Home</ButtonAction>
          </ButtonWrapper>
        </Section>
      </Main>
    </Layout>
  );
};

export default NotFoundPage;
